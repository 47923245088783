<template>
  <div class="row mx-0 h-100 justify-content-center">
    <div class="col-12 p-0">
      <ContentWithBackgroundAndFooter>
        <div class="row m-0 justify-content-center">
          <div class="col-12 my-4 bold text-center">
            I would like to donate the following amount daily:
          </div>
          <div class="col-10 p-0 text-center mb-4">
            <div class="row justify-content-center">
              <div class="col-3 text-center mb-2 px-2" v-for="amount in defaultAmounts" :key="amount">
                <div class="select_amount select_amount_size" :class="{selected: amount === selectedAmount}" @click="useSelected(amount)">
                  R{{amount}}
                </div>
              </div>
              <div class="col-9 mb-2 px-2">
                <CurrencyField type="text" placeholder="0.00" id="customamount" v-model="customAmount" :currencySymbol="defaultCurrency" :color="{goldgold: customAmount, gold: !customAmount }" />
              </div>
            </div>
          </div>
        </div>
      </ContentWithBackgroundAndFooter>
    </div>
    <div class="col-12 p-3 bg text-center">
      <Button color="gold" btnText="Setup Now" icon="arrow" @buttonClicked="setupSadaqah" :disabled="!donationBaseAmount || donationBaseAmount <= 0" :isLoading="isLoading">
        <IconArrowForward color="black" size="size20" />
      </Button>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  props: ['modelValue'],
  emits: ['update:modelValue', 'continue'],
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    ContentWithBackgroundAndFooter: defineAsyncComponent(() => import('@/components/styling/ContentWithBackgroundAndFooter.vue'))
  },
  name: 'Daily Sadaqah Initial Form',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      selectedAmount: '1',
      customAmount: null,
      defaultAmounts: ['1', '2', '3', '5', '10'],
      donationBaseAmount: null,
      isLoading: false
    }
  },
  computed: {
    baseAmountValue: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  mounted () {
    if (this.baseAmountValue) {
      this.donationBaseAmount = this.baseAmountValue
    } else {
      this.useSelected(this.selectedAmount)
    }
  },
  watch: {
    customAmount () {
      this.checkAmount()
    }
  },
  methods: {
    useSelected (val) {
      this.customAmount = null
      this.selectedAmount = val
      this.donationBaseAmount = this.selectedAmount
    },
    checkAmount () {
      if (this.customAmount || this.customAmount === 0 || this.customAmount === '') {
        this.selectedAmount = null
        this.donationBaseAmount = this.customAmount
      }
    },
    setupSadaqah () {
      this.isLoading = true
      this.baseAmountValue = this.donationBaseAmount
      this.$emit('continue')
      this.isLoading = false
    }
  }
}
</script>

<style scoped>

.bg {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.select_amount {
  color: var(--gold-color);
  font-weight: 700;
  background-color: var( --green-color-dark );
  cursor: pointer;
}
.select_amount:hover {
  background-color: var( --green-color );
}
.select_amount_size {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  line-height: 44px;
}
.select_amount.selected {
  color: var( --green-color-dark );
  background-color: var(--gold-color);
}

</style>
